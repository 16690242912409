import { Link } from '@inertiajs/react';

interface FireLogoProp {
  size: 'normal' | 'small';
}

export default function FireLogo({size}: FireLogoProp) {
  return (
    <Link href='/'>
        <img  
            src="https://www.vebingo.com/image/cachewebp/catalog/vebingo-logo-beyaz-2052x664.webp" 
            alt="Company logo" 
            className={`h-7  ${size == 'normal' ? 'lg:h-12' : 'lg:h-10' }`}
        />
    </Link>
  )
}
